<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Rekap Pemesanan</b>
            </div>
            <br/>
            
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Bulan dengan format 01" type="text" class="form-control" v-model="bulan" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Tahun" type="text" class="form-control" v-model="tahun" />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Cari</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInputs()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Batal
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <button
              @click="exportExcelData('daftarlaporanpemesanan')"
              class="btn btn-sm btn-success"
            >
              Export Excel
            </button>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="pagination"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    return {
      headers: {
        nama_kegiatan: {
          label: "Nama Kegiatan",
          sortable: true,
        },
        tanggal_kegiatan: {
          label: "Tanggal Kegiatan",
          sortable: true,
        },
        waktu: {
          label: "Waktu Kegiatan",
          sortable: true,
        },
        nama_ruangan: {
          label: "Nama Ruangan",
          sortable: true,
        },
        kapasitas_ruangan: {
          label: "Kapasitas Ruangan",
          sortable: true,
        },
        jumlah_peserta: {
          label: "Jumlah Peserta",
          sortable: true,
        },
        kontak_person: {
          label: "Kontak Person",
          sortable: true,
        },
        opd_pembuat: {
          label: "OPD",
          sortable: true,
        },
        status_kegiatan: {
          label: "Status Kegiatan",
          sortable: true,
        },
      },
      options: [],
      isTouched: false,
      isLoading: false,
      isDisabled: false,
    };
  },
  computed: {
    items() {
      return this.$store.state.rekapPesan.items
        ? this.$store.state.rekapPesan.items.items
        : [];
    },
    filters() {
      return this.$store.state.rekapPesan.items
        ? this.$store.state.rekapPesan.items.filters
        : {};
    },
    state() {
      return this.$store.state.rekapPesan;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.rekapPesan) {
        return !this.$store.state.profile.permissions.rekapPesan.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.rekapPesan) {
        return !this.$store.state.profile.permissions.rekapPesan.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.rekapPesan) {
        return !this.$store.state.profile.permissions.rekapPesan.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
    };
    this.treeKlasifikasi();
    this.$store.commit("rekapPesan/STATE", state);
    this.get();
  },
  methods: {
    exportExcelData(val) {
      const link = document.createElement("a");
      let unit_id = JSON.parse(localStorage.getItem("user")).unit_id;
      link.href = `${this.baseUrl}/${val}/export-excel?bulan=${this.bulan}&tahun=${this.tahun}&unit_id=${unit_id}`;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    treeKlasifikasi() {
      // axios.get(`/clasificationdoc/get_list_klasifikasi`).then((res) => {
      //   this.options = res.data.data;
      // });
      this.options = [
        { id: 1, label: "Disetujui" },
        { id: 0, label: "Tunggu" },
        { id: 2, label: "Ditolak / Dibatalkan" },
      ];
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        bulan: this.bulan,
        tahun: this.tahun,
        // kapasitas_ruangan: this.kapasitas_ruangan,
        // status_pemesanan: this.status_pemesanan,
        cari: this.cari,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("rekapPesan/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("rekapPesan/getRekapPesan", val);
    },
    resetInputs() {
      this.cari = "";
      this.bulan = "";
      this.tahun = "";
      // this.kapasitas_ruangan = "";
      // this.status_pemesanan = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        bulan: this.bulan,
        tahun: this.tahun,
        // kapasitas_ruangan: this.kapasitas_ruangan,
        // status_pemesanan: this.status_pemesanan,
        cari: this.cari,
        asal: this.asal,
      };
      this.$store.commit("rekapPesan/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    resetInput() {
      this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.nomor_agenda = "";
      this.periode_surat = "";
      this.perihal = "";
      this.nomor_surat = "";
      this.bulan = "";
      this.tahun = "";
      // this.kapasitas_ruangan = "";
      // this.status_pemesanan = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        bulan: this.bulan,
        tahun: this.tahun,
        // kapasitas_ruangan: this.kapasitas_ruangan,
        // status_pemesanan: this.status_pemesanan,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("rekapPesan/STATE", state);
      this.get(state);
      this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        bulan: this.bulan,
        tahun: this.tahun,
        // kapasitas_ruangan: this.kapasitas_ruangan,
        // status_pemesanan: this.status_pemesanan,
      };
      this.$store.commit("rekapPesan/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("rekapPesan/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("rekapPesan/onEdit", val);
    },
    doApprove(val) {
      this.$store.dispatch("rekapPesan/submitApprove", val);
    },
    doBatalPesan(val) {
      this.$store.dispatch("rekapPesan/submitBatalPesan", val);
    },
    doEdit(val) {
      this.$store.dispatch("rekapPesan/onEdit", val);
    },
    doPemesanan(val) {
      this.$store.dispatch("rekapPesan/onPemesanan", val);
    },
    doDelete(val) {
      this.$store.dispatch("rekapPesan/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("rekapPesan/STATE", state);
      this.get(state);
    },
  },
};
</script>
